import React from 'react'
import Layout from '../components/Layout'
import Index from '../page/Index/Index'

// import Team from '../page/Team/Team'

const TeamPage = () => (
  <Layout>
    <Index />
  </Layout>
)

export default TeamPage
